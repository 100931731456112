<template>
  <div id="notfound" class="grid">
    <div class="pg-header">
      <h1>404</h1>
      <h3>Sidan kunde inte hittas</h3>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
